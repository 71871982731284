<template>
  <div class="body" :style="{'background': 'url(' + source_domain + '/images/bj.jpg) no-repeat center center', 'background-size': '100% 100%'}">
    <form id="loginform">
      <div id="login" :style="{'background': 'url(' + source_domain + '/images/bj2.png) no-repeat center center', 'background-size': '80% 80%'}">
        <div id="login1"><input type="text" v-model="login.username" @keyup.enter="putLogin" placeholder="请输入账号  " class="input" /></div>
        <div id="login2"><input type="password" v-model="login.password" @keyup.enter="putLogin" placeholder="请输入密码" class="input" /></div>
        <div id="btn">
          <span class="btn_dl" @click="putLogin"></span>
        </div>
      </div>
    </form>
    <div id="elecData_father">
      <vue-canvas-nest :config="config" :el="'#elecData_father'"></vue-canvas-nest>
    </div>
  </div>
</template>

<script>
import { setToken } from '../utils/auth'
import vueCanvasNest from "vue-canvas-nest"
export default {
  components: {
    vueCanvasNest
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      login: {
        username: '',
        password: ''
      },
      config: {
        color: "255, 255, 255",
        opacity: 1,
        zIndex: -1,
        count: 200,
      }
    };
  },
  created() {
    //
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    resetInput () {
      var that = this
      that.login.username = ''
      that.login.password = ''
    },
    putLogin () {
      var that = this
      if(that.login.username == '') {
        that.tips('用户名不能为空！')
        return ''
      }
      if(that.login.password == '') {
        that.tips('密码不能为空！')
        return ''
      }
      that.showLoading()
      var param = that.login
      this.$api.merchant.authLogin(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          setToken(res.data.access_token)
          that.success('登录成功')
          that.$router.push({name: 'home'})
        } else {
          that.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style>
</style>
<style scoped>
  .body { position: fixed; top: 0; left: 0; width: 100%; height: 100%; }
  #login { width:800px; height:600px; margin:0 auto; margin-top:5%; }
  #login1 { padding-top:290px; }
  #login2 { margin-top:27px; }
  .input { margin-left:290px; padding:10px; width:300px; border:none; background:none; outline:none; color: #fff; }
  #btn { padding-top:46px; }
  .btn_dl { display: block;
    width: 111px;
    height: 40px;
    margin-left: 346px;
    float: left;
    cursor: pointer; }
  .btn_cz { display:block; width:100px; height:40px; margin-left:53px; float:left; cursor:pointer; }
  #elecData_father {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
</style>
